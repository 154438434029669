import React, { useState, useEffect, useRef } from "react";
import "./imageSlider.css";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import arrowRight from "../../assets/svg/arrowRight.svg";

import Loader from "../Loader/Loader";

import Electrolux from "../../assets/SliderProductsImages/Incasso/Electrolux.png";
import ElectroluxProfessioanl from "../../assets/SliderProductsImages/LiberaInstallazione/ElectroluxProfessional.png";
import GRF from "../../assets/SliderProductsImages/Incasso/GRF.svg";
import Hisense from "../../assets/SliderProductsImages/LiberaInstallazione/Hisense.png";
import Berkel from "../../assets/SliderProductsImages/LiberaInstallazione/BERKEL.jpeg";
import LC from "../../assets/SliderProductsImages/Incasso/ELLECI.jpeg";
import AEG from "../../assets/SliderProductsImages/Incasso/AEG.png";
import CandyLogo from "../../assets/SliderProductsImages/Incasso/CandyLogo.svg";
import LG from "../../assets/SliderProductsImages/LiberaInstallazione/LG.png";
import LGBI from "../../assets/SliderProductsImages/Incasso/LGBI.jpeg";
import Beko from "../../assets/SliderProductsImages/LiberaInstallazione/Beko.png";
import Faber from "../../assets/SliderProductsImages/Incasso/FABER.jpeg";
import Sangiorgio from "../../assets/SliderProductsImages/Incasso/SANGIORGIO.jpeg";

export default function ImageSlider({
  images,
  currentImageIndex,
  setCurrentImageIndex,
  isPlaying,
}) {
  const logos = [
    { name: "Candy", image: CandyLogo },
    { name: "Electrolux", image: Electrolux },
    { name: "Electrolux", image: Electrolux },
    {
      name: "Electrolux",
      image: Electrolux,
    },
    { name: "Electrolux Professional", image: ElectroluxProfessioanl },

    {
      name: "GRF",
      image: GRF,
    },
    {
      name: "Hisense",
      image: Hisense,
    },
    { name: "Berkel", image: Berkel },
    { name: "LG", image: LG },
    {
      name: "ELLECI",
      image: LC,
    },
    {
      name: "AEG",
      image: AEG,
    },
    {
      name: "Candy",
      image: CandyLogo,
    },
    {
      name: "CandyWarranty",
      image: CandyLogo,
    },
    { name: "LG", image: LG },
    { name: "LG", image: LGBI },
    {
      name: "Beko",
      image: Beko,
    },
    {
      name: "Beko",
      image: Beko,
    },
    {
      name: "Faber",
      image: Faber,
    },
    {
      name: "SanGiorgio",
      image: Sangiorgio,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const timer = useRef(null);

  const startTimer = () => {
    if (isPlaying) {
      timer.current = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 10000);
    }
  };

  useEffect(() => {
    startTimer(); // Start the timer initially

    return () => {
      clearInterval(timer.current);
    };
  }, [isPlaying]);

  useEffect(() => {
    // Reset the timer when currentImageIndex changes
    clearInterval(timer.current);
    startTimer();
  }, [currentImageIndex]);

  const handleImageLoad = () => {
    console.log("Image loaded"); // For debugging
    setIsLoading(false);
  };

  const nextImage = () => {
    setIsLoading(true);
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setIsLoading(true);
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="sliderMainImages">
      <img
        src={logos[currentImageIndex].image}
        alt={logos.name}
        className="sliderMainImageLogo"
      />
      <img
        src={arrowLeft}
        alt="Foto precedente"
        className="arrowLeft"
        onClick={prevImage}
        draggable="false"
      ></img>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {" "}
          <Loader />
        </div>
      )}
      <a href={images[currentImageIndex].link}>
        <img
          draggable="false"
          src={images[currentImageIndex].image}
          alt="slider"
          className="sliderMainImage"
          onLoad={handleImageLoad}
        />
      </a>

      <img
        draggable="false"
        src={arrowRight}
        alt="Foto successiva"
        className="arrowRight"
        onClick={nextImage}
      ></img>
    </div>
  );
}
